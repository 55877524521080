<template>
  <div class="main-page result-page">
    <div class="main-page__bg-wrapper main-page__top">
      <span class="main-page__bg"></span>
      <div class="main-page__bg-content">
        <Header />
        <Title>
          Статус заказа
        </Title>
        <form novalidate @submit.prevent="submit" class="result-page__form">
          <Field
            v-model="form.order_id"
            :error="formErrors.order_id"
            title="Номер заказа"
          />
          <Field
            title="Email"
            :error="formErrors.email"
            name="email"
            v-model="form.email"
          />
          <Button type="submit">
            Узнать статус заказа
          </Button>
        </form>
        <div class="result-page__status" v-if="status">
          <p class="result-page__status-text">
            Статус по заказу <span>{{ currentData.order_id }}</span><br/> {{ statusText }}
          </p>
          <div class="result-page__status-links" v-if="status == 4">
            <a
              :href="`/api/get-egrn/pdf?order_id=${currentData.order_id}&email=${currentData.email}`"
              target="_blank"
            >Скачать в формате PDF</a
            ><br/>
            <a
              target="_blank"
              :href="`/api/get-egrn/html?order_id=${currentData.order_id}&email=${currentData.email}`"
            >Скачать в формате HTML</a
            >
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/uniq/header/Header'
import Field from '@/components/gui/field/Field'
import Button from '@/components/gui/button/Button'
import Title from '@/components/gui/title/Title';

import validationMixin from '@/mixins/validation'
import Validation from '@/ext/validation/Validation'

import getStatus from '@/api/getStatus'

import './result-page.scss';

export default {
  components: {
    Field,
    Header,
    Button,
    Title,
    Footer: () => import('@/components/uniq/footer/Footer'),
  },
  mixins: [
    validationMixin,
  ],
  data() {
    return {
      status: null,
      form: {
        email: '',
        order_id: '',
      },
      currentData: {},
      formRules: {
        order_id: [Validation.REQUIRED, [Validation.EXP, /(^\d*$)|(^$)/]],
        email: [Validation.REQUIRED, Validation.EMAIL]
      },
      customErrors: {
        order_id: {
          [Validation.EXP]: 'Допустимы только числа'
        }
      }
    }
  },
  computed: {
    statusText() {
      let statusText = '';

      switch (this.status) {
        case 2:
          statusText = 'Заказ принят в обработку';
          break;

        case 3:
          statusText = 'Заказ в процессе выполнения';
          break;

        case 4:
          statusText = 'Заказ готов';
          break;

        case 5:
          statusText = 'Ошибка выполнения заказа';
          break;

        case 6:
          statusText = 'Заказ отменен';
          break;

        default:
          break;
      }

      return statusText;
    }
  },
  methods: {
    submit() {
      this.status = null;

      this.validate()

      this.isValid && this.getStatus()
    },
    async getStatus() {
      this.currentData = JSON.parse(JSON.stringify(this.form));
      const data = await getStatus(this.form);

      if (!data.Success) {
        alert('Произошла ошибка! Попробуйте позже')
        return;
      }

      this.status = data.Status
    }
  },
}
</script>
