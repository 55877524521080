import Validation from '@/ext/validation/ValidationWithFocus'

function setFormErrors(form, formErrors) {
  Object.keys(form).forEach(key => {
    const value = typeof form[key] === 'object' && [key] !== null ? {} : ''

    this.$set(
      formErrors,
      key,
      value
    )

    if (value)
      setFormErrors.call(this, form[key], formErrors[key])
  })
}

export default {
  data() {
    return {
      form: {},
      formRules: {},
      formErrors: {},
      customErrors: {},
      formIsSubmit: false,
      isValid: false
    }
  },
  created() {
    setFormErrors.call(this, this.form, this.formErrors)
  },
  watch: {
    formIsSubmit() {
      this.$watch('form', () => this.validate(false), { deep: true })
    }
  },
  methods: {
    validate(focus = true) {
      this.formIsSubmit = true
      const selector = focus ? '.field_error input' : null
      const validation = new Validation(selector, this.validationData(this.form), this.formRules, this.customErrors).make()

      this.isValid = validation.isValid

      this.formErrors = validation.errors
    },
    validationData(data) {
      return data
    }
  }
}